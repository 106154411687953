import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Text, Button, Image } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"

import wizardLeftImage from '../images/spots/wizard-left.png'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Breadcrumbs subtitle="404" title="Page Not Found" />
    
    <Grid variant="base" gap={[4, 6, 8]} columns={[1, 2]} sx={{ alignItems: "center" }}>
      <Box>
        <Text as="p" variant="body" sx={{ fontSize: "md" }}>The page you are looking for</Text>
        <Text as="p" variant="body" sx={{ fontSize: "md" }}>Has sadly been lost</Text>
        <Text as="p" variant="body" sx={{ fontSize: "md" }}>Perhaps to time, or neglect,</Text>
        <Text as="p" variant="body" sx={{ fontSize: "md" }}>Or some mischief.</Text>
        <Text as="p" variant="body" sx={{ fontSize: "md" }}>But fear not, adventurer — </Text>
        <Text as="p" variant="body" sx={{ fontSize: "md" }}>You are never alone</Text>
        <Text as="p" variant="body" sx={{ fontSize: "md" }}>and with friends,</Text>
        <Text as="p" variant="body" sx={{ fontSize: "md" }}>your fate will not be consigned to the endless expanse of oblivion.</Text>
        <Link to="/">
          <Button variant="secondary" sx={{ mt: 4 }}>Return Home</Button>
        </Link>
      </Box>

      <Image src={wizardLeftImage} alt="Wizard" sx={{ maxWidth: "100%" }} />
    </Grid>
  </Layout>
)

export default NotFoundPage
